"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "en", {
  enumerable: true,
  get: function () {
    return _translations.en;
  }
});
Object.defineProperty(exports, "fr", {
  enumerable: true,
  get: function () {
    return _translations2.fr;
  }
});
var _translations = require("./en/translations");
var _translations2 = require("./fr/translations");