import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImageFullBig from './icons/fullscreen-big.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import './css/style.css';

export default class FullScreen extends Plugin {
    init() {
        const editor = this.editor;
        
        editor.ui.componentFactory.add( 'fullScreen', locale => {
            const view = new ButtonView( locale );
            view.set( {
                label: 'Preview',
                icon: ImageFullBig,
                tooltip: true,
                class: 'ck-fullscreen__toggle',
                withText: true,
            } );

            // Callback executed once the image is clicked.
            view.on('execute', () => {
                editor.enableReadOnlyMode('my-feature-id');
                document.body.setAttribute("id", "fullscreenoverlay");
                const markup = editor.sourceElement.nextElementSibling.querySelector('.ck-content');
                const clone = markup.cloneNode(true);
                clone.setAttribute("id", 'fullscreeneditor');
                clone.setAttribute("contenteditable", false);
                clone.setAttribute('class', 'ck ck-content ck-rounded-corners');
                clone.querySelectorAll('.wsc-contenteditable-mirror, .ck-widget__type-around__button').forEach(e => e.remove());
                let section = document.createElement('div');
                section.classList.add('uk-main__heading');
                let title = document.createElement('div');
                title.classList.add('uk-main__title');
                let h1 = document.createElement('h1');
                h1.textContent = "Preview";
                let btn = document.createElement('button');
                btn.classList.add('uk-button', 'uk-bdcolor--alert', 'uk-bghcolor--alert', 'tiny');
                btn.textContent = '×';
                btn.setAttribute("onClick", 'document.body.querySelector("#fullscreeneditor").remove()')
                
                title.appendChild(h1);
                section.appendChild(title);
                section.appendChild(btn);
                clone.prepend(section);
                document.body.appendChild(clone);
                editor.disableReadOnlyMode('my-feature-id');
            });

            return view;
        } );
    }
}
