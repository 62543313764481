"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "en", {
  enumerable: true,
  get: function () {
    return _locales.en;
  }
});
Object.defineProperty(exports, "fr", {
  enumerable: true,
  get: function () {
    return _locales.fr;
  }
});
var _locales = require("./locales");