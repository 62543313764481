import React from 'react';
import { AppWrapper } from '@sm360/apps-core';
import * as i18nRessources from '@sm360/apps-i18n';
import { ReactComponent as SvgSprite } from '@sm360/ui-xms/dist/assets/svg-sprite.svg';
import packageJson from '../package.json';
import Navigation from './navigation';

const App = () => {
    return (
        <AppWrapper
            ready
            autoUpdate
            defaultTitle="XMS360"
            titleTemplate="XMS360 - %s"
            packageJson={packageJson}
            appNavList={Navigation}
            svgSprite={<SvgSprite />}
            accessRight="XmsAccess"
            i18nRessources={i18nRessources}
            withSupport
        />
    );
};

export default App;
